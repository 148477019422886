@import "tailwindcss/base";
@import "tailwindcss/components";

/*
 * Jumpstart Pro default styles
 * Remove these if you'd like to use TailwindUI which expects a clean slate
*/
@import "themes/dark";
@import "themes/juicy";
@import "themes/light";
@import "themes/jumpstart-dark";
@import "themes/jumpstart";
@import "components/base";
@import "components/actiontext";
@import "components/alert";
@import "components/animation";
@import "components/announcements";
@import "components/avatars";
@import "components/buttons";
@import "components/cards";
@import "components/code";
@import "components/connected_accounts";
@import "components/direct_uploads";
@import "components/docs";
@import "components/icons";
@import "components/iframe";
@import "components/nav";
@import "components/notifications";
@import "components/pagination";
@import "components/pills";
@import "components/strada";
@import "components/tabs";
@import "components/toasts";
@import "components/tooltips";
@import "components/top_nav";
@import "components/trix";
@import "components/typography";
@import "components/util";
@import "components/wells";
@import "components/braintree";

@import "tippy.js/dist/tippy.css";
@import "tom-select/dist/css/tom-select.css";
@import "daisyui/dist/full.css";
@import "tailwindcss/utilities";

.input, .select {
  --tw-bg-opacity: 0;
}

.z-30 {
  z-index: 30;
}

@media (min-width: 1536px) {
  .container {
    max-width: 1280px;
  }
}

.trix-button-group.trix-button-group--file-tools {
  display:none;
}

.search-box input {
  border-radius: 0px !important;
}

.search-box .btn {
  border-radius: 2px !important;
}

.search-box *:focus {
  border: none !important;
  outline: none;

}

#album .award-preview:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: #221e1e;
  border-radius: 0.25rem;
}

.dm-thread:hover {
  background-color: #f5f5f5;
}

.dm-thread.active {
  background-color: #f5f5f5;
}

.dm-thread .participant {
  font-weight: bold;
  width: 100%;
}

.dm-thread .title {
  font-size: 0.8em;
}

.page a {
  font-weight: 600;
}

.page.active,
.page.disabled {
  border: none !important;
  padding: 0 0 0 0 !important;
}

.page.active a {
  color: #fff !important;
  border: 1px solid #3d74ea !important;
  background-color: #3d74ea !important;
}

.page.disabled a {
  background-color: #f5f5f5 !important;
}

.dm-thread.active {
  background-color: #aaa;
}

#dm-container .indicator-item {
  border: 1px solid #10ffbe;
  border-radius: 50%;
  bottom: 3px;
  left: 12px;
}
