.font-display {
  @apply text-5xl font-bold leading-tight tracking-tight;
}


h1, .h1 {
  @apply text-4xl font-bold leading-tight tracking-tight;
}

h2, .h2 {
  @apply text-3xl font-bold leading-tight;
}

h3, .h3 {
  @apply text-2xl font-bold leading-normal;
}

h4, .h4 {
  @apply text-xl font-bold leading-normal;
}

h5, .h5 {
  @apply text-lg font-bold leading-normal;
}

h6, .h6 {
  @apply font-bold;
}

.link {
  color: var(--text-primary);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  text-decoration-line: underline;

  &:hover,
  &:focus {
    @apply text-primary-600;
  }
}
