.avatar {
  font-size: 13px;
  font-weight: 600;
  position: relative;
  display: inline-flex;
  width: 3rem;
  height: 3rem;
  min-width: 14px;
  color: #fff;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;

  .avatar-small {
    position: absolute;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 0 2px #fff;
  }
}
